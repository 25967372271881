<template>
  <div class="darkroom" v-loading="loading">
    <el-form>
      <el-form-item>
        <div slot="label" class="form-label">
          <span>Unsplash 地址</span>
        </div>
        <el-input v-model="unsplashUrl" @keyup.enter.native="unsplashEnter">
          <template slot="suffix" v-if="unsplash_id">
            <i v-if="unsplash_id !== originUnsplashId" class="unsplash-btn el-input__icon el-icon-check" @click="applyUnsplashImage"></i>
            <a v-else target="_blank" :href="unsplashUrl">
              <i class="unsplash-btn el-input__icon el-icon-search"></i>
            </a>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item>
        <div slot="label" class="form-label">
          <span>七牛地址</span>
        </div>
        <el-input v-model="oUrl"></el-input>
      </el-form-item>
    </el-form>
    <div class="table">
      <div class="cropper-wrapper" v-if="activeCropper">
        <div class="cropper-wrapper-2">
          <img class="cropper-padding" :src="`${oUrl}?imageMogr2/thumbnail/!210x210r`" />
          <vueCropper v-if="imageInfo" class="cropper" ref="cropper" :img="`${oUrl}?imageMogr2/thumbnail/!210x210r`" :auto-crop="true" :fixed="false" :output-size="1" :can-scale="false" :info="false" output-type="jpeg" @realTime="$emit('realTime', $event)"></vueCropper>
        </div>
        <div class="image-size-infos">
          <div>整图尺寸: {{ parseInt(imageInfo.width) }} x {{ parseInt(imageInfo.height) }}</div>
          <div>选区尺寸: {{ parseInt(crop.width / cropScale) }} x {{ parseInt(crop.height / cropScale) }}</div>
        </div>
        <div class="image-size-infos">
          <div>x: {{ crop.dx }}, y: {{ crop.dy }}</div>
        </div>
        <el-upload class="upload-wrapper" action="https://upload.qiniup.com" :before-upload="beforeUpload" :on-success="updateImageSuccess" :on-error="updateImageError" :data="{ token: token }" :show-file-list="false">
          <el-button size="small" type="danger">更换图片</el-button>
        </el-upload>
      </div>
      <el-upload v-else class="uploader" action="https://upload.qiniup.com" :before-upload="beforeUpload" :on-success="updateImageSuccess" :on-error="updateImageError" :data="{ token: token }" :drag="true" :show-file-list="false">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          将文件拖到此处，或
          <em>点击上传</em>
        </div>
      </el-upload>
    </div>
  </div>
</template>

<script>
function getCropOption(txt) {
  console.log(txt);
  const p = /\/crop\/!(\d+)x(\d+)a(\d+)a(\d+)/gi;
  const CropOption = p.exec(txt);
  console.log(CropOption);
  return CropOption;
}
import VueCropper from '@/components/cropper';

import Unsplash, { toJson } from 'unsplash-js';
const unsplash = new Unsplash({
  applicationId: '71264520737023fe4b22c54a4e892f3bf4a065e8fd33ca1e5f0e5aed0afa5180',
  secret: 'aa8dd4174b1e9dfad3d8b644673427d7f61226e0b106abee4237ec961802e428',
});

export default {
  name: 'Darkroom',
  components: {
    VueCropper,
  },
  props: {
    form: {
      type: Object,
    },
    activeLang: {
      type: String,
      default: 'zh-Hans',
    },
    crop: {
      type: Object,
    },
    cropScale: {
      type: Number,
    },
    imageInfo: {
      type: Object,
    },
  },
  data() {
    return {
      token: '',
      unsplash_id: '',
      originUnsplashId: '',
      loading: false,
      devices: [
        { name: 'iPad', scale: 0.2, showDetail: false },
        { name: 'iPad-Landscape', scale: 0.3, showDetail: false },
        { name: 'iPhone-X', scale: 0.4, showDetail: false },
        { name: 'iPhone-8', scale: 0.4, showDetail: false },
      ],
      activeCropper: false,
      dialogShow: {
        dailypic: false,
        focusSharePic: false,
        sleepSharePic: false,
        wxCalendar: false,
      },
    };
  },
  computed: {
    cropData() {
      const scale = this.cropScale;
      const size = `${parseInt(this.crop.width / scale)}x${parseInt(this.crop.height / scale)}`;
      const dx = parseInt(this.crop.dx / scale);
      const dy = parseInt(this.crop.dy / scale);
      return {
        dx,
        dy,
        size,
      };
    },
    cropPath() {
      const { dx, dy, size } = this.cropData;
      return `!${size}a${dx}a${dy}`;
    },
    oUrl: {
      get() {
        let url = this.form.pic_url;
        if (this.form.raw_pic) {
          url = this.form.raw_pic.split('?')[0];
        }
        return url || '';
      },
      set(url) {
        this.form.raw_pic = url;
      },
    },
    unsplashUrl: {
      get() {
        if (this.unsplash_id) {
          return `https://unsplash.com/photos/${this.unsplash_id}`;
        } else {
          return '';
        }
      },
      set(val) {
        const unsplashId = val.replace('https://unsplash.com/photos/', '').split('/')[0];
        this.unsplash_id = unsplashId;
      },
    },
  },
  watch: {
    'form.raw_pic': async function () {
      this.getUnsplashId();
      this.refetchCripper();
    },
    cropPath(val) {
      this.$emit('updateCropPath', val);
    },
  },
  methods: {
    refetchCripper() {
      const picUrl = this.form.raw_pic;
      this.activeCropper = false;
      clearTimeout(this.updateImageHandle);
      // this.updateImageHandle = setTimeout(this.updateImage, 500)
      this.updateImageHandle = setTimeout(async () => {
        await this.updateImage();
        this.activeCropper = true;
        setTimeout(() => {
          const picData = getCropOption(picUrl);
          if (picData) {
            const [, width, height, dx, dy] = getCropOption(picUrl);
            const scale = this.cropScale;
            console.log('width, height, dx, dy');
            console.log(scale, width, height, dx, dy);
            this.$refs.cropper.changeCropOptions(parseInt(width * scale), parseInt(height * scale), parseInt(dx * scale), parseInt(dy * scale));
          }
        }, 200);
      }, 0);
    },
    unsplashEnter() {
      if (this.unsplash_id) {
        this.applyUnsplashImage();
      }
    },
    async applyUnsplashImage() {
      this.loading = true;
      unsplash.photos
        .getPhoto(this.unsplash_id)
        .then(toJson)
        .then(async (json) => {
          if (json.errors) {
            this.$message.error(json.errors.join('; '));
          } else {
            try {
              const baseMlsUrl = this.$env === 'production' ? 'https://davinci.moreless.space/api' : 'https://leonardo.moreless.space/api';

              const unsplashImageUrl = json.urls.full;
              const res = await this.$request({
                url: `${baseMlsUrl}/v1/admin/fetch_pic`,
                method: 'POST',
                timeout: 120000,
                data: {
                  unsplash_id: this.unsplash_id,
                  url: unsplashImageUrl,
                  folder: 'dailypics',
                },
              });
              const rawPic = res.data.url;
              await this.checkImageUsageRecord(rawPic);
              this.form.raw_pic = rawPic;
              this.originUnsplashId = this.unsplash_id;
            } catch (e) {
              this.$message.error(e.message);
            }
          }
          this.loading = false;
        });
    },
    updateImage: async function () {
      let imageInfo = null;
      const imageInfoResp = await this.$request({
        auth: undefined,
        url: `${this.oUrl}?imageInfo`,
        withCredentials: false,
      });
      imageInfo = imageInfoResp.data;
      let scale;
      console.log(imageInfo);
      if (imageInfo.width < imageInfo.height) {
        scale = 210 / imageInfo.width;
      } else {
        scale = 210 / imageInfo.height;
      }
      this.$emit('updateImage', {
        cropScale: scale,
        imageInfo: imageInfo,
      });
    },
    async updateImageSuccess(response) {
      const rawPic = `https://pics.tide.moreless.io/${response.key}`;
      try {
        await this.checkImageUsageRecord(rawPic);
        this.form.raw_pic = rawPic;
      } catch (e) {
        this.$message.error(e.message);
      }
      this.loading = false;
    },
    updateImageError() {
      this.loading = false;
    },
    async checkImageUsageRecord(url) {
      const res = await this.$request({
        url: '/v1/admin/check_image_usage_record',
        method: 'POST',
        data: { url, folder: 'dailypics' },
      });
      const usageRecords = res.data.dailypics;
      if (usageRecords.length) {
        const errorRocords = usageRecords.map((record) => record.date).join(', ');
        throw new Error(`图片已经在 ${errorRocords} 使用过`);
      }
      return res.data;
    },
    beforeUpload: async function () {
      const getTokenResp = await this.$request({
        url: '/v1/admin/dailypics/qiniu_token',
      });
      this.token = getTokenResp.data.token;
      this.loading = true;
    },
    async getUnsplashId() {
      try {
        const res = await this.$request({
          url: '/v1/admin/check_image_info',
          method: 'POST',
          data: {
            url: this.oUrl,
          },
        });
        this.originUnsplashId = res.data.unsplash_id;
        this.unsplash_id = res.data.unsplash_id;
      } catch (e) {
        this.unsplash_id = '';
      }
    },
  },
  created() {
    if (this.form.raw_pic) {
      this.getUnsplashId();
      this.refetchCripper();
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.darkroom {
  min-width: 315px;
  /deep/ .el-form-item__label {
    position: relative;
    z-index: 2;
  }
}
.cropper-wrapper {
  display: flex;
  margin: 0 auto;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
}
.cropper-wrapper-2 {
  position: relative;
  display: inline-block;
  margin: 0 auto;
}
.cropper-padding {
  display: block;
  visibility: hidden;
}
.vue-cropper.cropper {
  position: absolute;
  left: 0;
  top: 0;
}
.preview-wrapper {
  flex-grow: 1;
}
.upload-wrapper {
  display: inline-block;
  margin: 16px auto;
}
.image-size-infos {
  font-size: 13px;
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.unsplash-btn {
  cursor: pointer;
  font-size: 21px;
  color: #409eff;
}
</style>
