<template>
  <router-view v-if="$route.name !== 'MedArticleManager'"></router-view>
  <div v-else>
    <container>
      <div slot="page-header-extra">
        <page-header>
          <i slot="logo" class="material-icons">book</i>
          <h3 slot="title">图文管理</h3>
          <p slot="desc">文案征集</p>
        </page-header>
      </div>
      <div class="container" slot="container">
        <div class="container-head">
          <div class="container-head-title">图文管理</div>
          <div class="container-head-extra">
            <el-button :disabled="!$store.getters.hasEditRole" @click="addItem" type="text">新建图文</el-button>
          </div>
        </div>
        <div class="container-body">
          <el-table :data="list" stripe style="width: 100%" v-infinite-scroll="fetchList" infinite-scroll-disabled="infiniteScrollDisabled" infinite-scroll-distance="50" @row-dblclick="rowDblClick">
            <el-table-column label="专辑图片">
              <template slot-scope="scope">
                <div class="image">
                  <cover :width="48" :height="48" :src="scope.row.image" :mask-colors="scope.row.mask_colors"></cover>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="图文标题">
              <template slot-scope="scope">
                <p>{{ getTR(scope.row.title) }}</p>
                <el-button type="text" @click="copyToBoard(scope.row.id)">{{ scope.row.id }} </el-button>
              </template>
            </el-table-column>
            <el-table-column label="状态">
              <template slot-scope="scope">{{ getStatus(scope.row.status) }}</template>
            </el-table-column>
            <el-table-column label="创建时间">
              <template slot-scope="scope">{{ $moment(scope.row.created_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</template>
            </el-table-column>
            <el-table-column label="操作" align="right">
              <template slot-scope="scope">
                <el-button type="text" @click="editItem(scope.row)">编辑</el-button>
                <el-button type="text" :disabled="!($env === 'dev' || $store.getters.hasAdminRole)" @click="removeItem(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </container>
  </div>
</template>

<script>
// import _ from 'lodash'
import Container from '@/components/container';
import PageHeader from '@/components/page-header';
import Cover from '@/components/cover';
import allRegions from '@/regions.json';
import allStatus from '@/status.json';

const regionOptions = Object.keys(allRegions).map((value) => {
  const label = allRegions[value];
  return { value, label };
});

export default {
  name: 'MedArticleManager',
  components: {
    Container,
    PageHeader,
    Cover,
  },
  data() {
    return {
      lang: 'zh-Hans',
      regionOptions: regionOptions,
      langs: [
        {
          value: 'en',
          label: 'en',
        },
        {
          value: 'zh-Hans',
          label: 'zh-Hans',
        },
        {
          value: 'zh-Hant',
          label: 'zh-Hant',
        },
      ],
      keyword: '',
      filterRegions: [],
      filterStatus: '',
      sortChanged: false,
      ready: false,
      busy: false,
      finish: false,
      scenes: [],
      JSONVisible: false,
    };
  },
  computed: {
    infiniteScrollDisabled() {
      return this.busy || this.finish;
    },
    list: {
      get() {
        return [...this.scenes].sort((a, b) => {
          return a.sort_key - b.sort_key;
        });
      },
      set(scenes) {
        for (let index in scenes) {
          console.log(index);
          index = parseInt(index);
          scenes[index].sort_key = index;
        }
        this.scenes = scenes;
        this.sortChanged = true;
      },
    },
  },
  watch: {
    '$route.name'(name) {
      if (name === 'MedArticleManager') {
        this.init();
      }
    },
    filterRegions() {
      this.refetch();
    },
    filterStatus() {
      this.refetch();
    },
  },
  methods: {
    showJSON(item) {
      this.detail = JSON.stringify(item, undefined, 2);
      this.JSONVisible = true;
    },
    editItem(item) {
      this.$router.push({
        name: 'EditMedArticle',
        params: {
          article_id: item.id,
        },
      });
    },
    addItem() {
      this.$router.push({
        name: 'AddMedArticle',
      });
    },
    async removeItem(item) {
      const { value } = await this.$prompt('此操作将永久删除该图文，请输入 “确定删除” 来确认该操作', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      });
      if (value !== '确定删除') {
        this.$message.error('输入错误');
      } else {
        try {
          await this.$request({
            url: `/v1/admin/meditation/articles/${item.id}`,
            method: 'DELETE',
          });
          this.list = this.list.filter((article) => article.id !== item.id);
          this.$message({
            message: '操作成功',
            type: 'success',
          });
        } catch (e) {
          this.$message.error('操作失败');
        }
      }
    },
    cancelSort() {
      this.init();
      this.sortChanged = false;
    },
    async saveSort() {
      await this.$request({
        url: '/v1/admin/scenes/sort_keys',
        method: 'POST',
        data: this.list.map(({ id, sort_key: sortKey }) => {
          return {
            id,
            sort_key: sortKey,
          };
        }),
      });
      this.sortChanged = false;
      this.$message({
        message: '操作成功',
        type: 'success',
      });
    },
    async init() {
      this.refetch();
    },
    refetch() {
      this.list = [];
      this.finish = false;
      this.fetchList();
    },
    fetchList: async function () {
      this.busy = true;
      const limit = 50;
      const params = { offset: this.list.length, limit };
      const res = await this.$request({
        url: '/v1/admin/meditation/articles',
        params,
      });
      const list = res.data;
      if (list.length < limit) {
        this.finish = true;
      }
      this.list = [...this.list, ...list];
      const $container = document.getElementById('main-container');
      const srcollTop = $container.scrollTop;
      this.$nextTick(() => {
        $container.scrollTop = srcollTop;
        this.busy = false;
      });
    },
    getTR(langDict) {
      return langDict['zh-Hans'] || langDict['zh-Hant'] || langDict.en;
    },
    getStatus(status) {
      return allStatus[status];
    },
    rowDblClick(row) {
      this.editItem(row);
    },
    copyToBoard(str) {
      this.$copyText(str).then(() => {
        this.$message('复制到剪切板');
      });
    },
  },
  async created() {
    await this.init();
    this.ready = true;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.container-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
}

.nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.add-btn {
  width: 100%;
  border-style: dashed;
}

.lang-selecter {
  margin-left: 16px;
}

.list-item {
  padding: 16px 0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
}

.list-item + .list-item {
  border-top: 1px solid #e8e8e8;
}
.list-main-item {
  flex-grow: 1;
  margin-left: 16px;
  width: 320px;
}

.el-form-item {
  margin-bottom: 0;
}

.avatar {
  width: 48px;
  height: 48px;
  flex-basis: 48px;
  flex-shrink: 0;
  text-align: center;
  line-height: 48px;
  color: #fff;
  background-color: gray;
}

.list-item-name {
  color: rgba(0, 0, 0, 0.65);
}

.list-item-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-left: 24px;
  a {
    color: #1890ff;
    cursor: pointer;
    &:hover {
      color: #40a9ff;
    }
  }
  a + a {
    margin-left: 8px;
  }
}

.advanced {
  margin-left: 8px;
  font-size: 13px;
  flex-shrink: 0;
  color: #1890ff;
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    color: #40a9ff;
  }
}

.list-item-name {
  font-size: 15px;
  font-weight: 500;
}

.btns-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-shrink: 0;
  margin-left: 16px;
}

.mark {
  font-size: 13px;
  color: #bcbec2;
}

.remove-btn {
  color: #f56c6c;
}

.image {
  width: 48px;
  height: 48px;
}
</style>
